import AccessControl from "../images/access_control.png";
import Fidelity from "../images/fidelity.png";
import VDS from "../images/vds.png";
import PendoApp from "../images/pendo_app.png";

export const projects: Project[] = [
  {
    company: "Pendo",
    product: "Visual Design Studio",
    description:
      "The Visual Design Studio offers a way to create custom messaging displayed to users. " +
      "I worked on feature development like adding more configuration and styling options. " +
      "I also created an automated end to end testing suite using Cypress; reducing regressions by 200% week-over-week. " +
      "The architecture is uniquely loaded over a customer's application using iframes, within is a Vue application and " +
      "it uses a custom built pub/sub mechanism to communicate between iframes." +
      "Technologies used include Vue, Node.js, Javascript, Jest, Cypress, HTML, CSS.",
    imgSrc: VDS,
  },
  {
    company: "Pendo",
    product: "Platform",
    description:
      "The Pendo platform allows customers to view visitor and usage analytics" +
      ", conduct experiments, manage guides, and manage page/feature tagging. I created new features " +
      "across the entire platform; notably the Resource Center as well as guide management. " +
      "Technologies used include Vue, Node.js, Javascript, Jest, Cypress, HTML, CSS",
    imgSrc: PendoApp,
  },
  {
    company: "Fidelity Investments",
    product: "Planning & Guidance Center",
    description:
      "Fidelity's Planning & Guidance Center is a way to manage a customer's goal planning " +
      "and offer them investment advice to help reach those goals. " +
      "I developed features on this high-volume application and conducted " +
      "experimentation to drive traffic from other parts of Fidelity.com. " +
      "Technologies and strategies used include React, HTML, CSS, Javascript, Java Spring, A/B testing, KPI setting.",
    imgSrc: Fidelity,
  },
  {
    company: "Storable",
    product: "Cloud Access Control",
    description:
      "Storable's Cloud Access Control solution provides full suite software for a " +
      "storage facility operator to perform their operations remotely. Actions like provisioning access, opening/closing a gate, and gate activity records. " +
      "When I joined, Cloud Access Control was a greenfield project. I played a large part in requirements gathering, designing, and implementing major components of the application. " +
      "The key strategy is using a Micro Frontend approach to embed this new application into an existing Storable product. " +
      "Techologies used include React, Javascript, .NET, Cypress, Jest, Postgres, Docker, Kubernetes, Kafka.",
    imgSrc: AccessControl,
  },
];
