import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

export const ProjectCard = ({
  project,
  onClick,
}: {
  project: Project;
  onClick: any;
}) => (
  <Card
    sx={{ minWidth: "500px", maxWidth: "500px", margin: "10px" }}
    variant="outlined"
  >
    <CardContent>
      <Typography sx={{ fontSize: 18 }} variant="h5">
        {project.company}
      </Typography>
      <Typography sx={{ fontSize: 16 }} color="text.secondary">
        {project.product}
      </Typography>
      <Box
        sx={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
      >
        <img src={project.imgSrc} alt="product" width="375vh" height="250vh" />
      </Box>
    </CardContent>
    <CardActions>
      <Button size="small" onClick={() => onClick(project)}>
        Learn More
      </Button>
    </CardActions>
  </Card>
);
