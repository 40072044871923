import { useState } from "react";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { ProjectCard } from "../components/ProjectCard";
import Modal from "@mui/material/Modal";

export const FeaturedWork = ({ projects }: { projects: Project[] }) => {
  const [open, setOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const handleOpen = (project: Project) => {
    setOpen(true);
    setSelectedProject(project);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProject(null);
  };

  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Box mt="12em" mb="6em">
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          Featured Work.
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {projects.map((project, i) => (
            <ProjectCard key={i} project={project} onClick={handleOpen} />
          ))}
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            marginBottom="1em"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {selectedProject?.product}
            </Typography>
            <IconButton sx={{ color: "black" }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <img
            src={selectedProject?.imgSrc}
            alt="product"
            width="375vh"
            height="250vh"
          />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {selectedProject?.description}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
