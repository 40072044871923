import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";

export const NavBar = () => {
  const linkStyles = { color: "black", textDecoration: "none" };
  const menuItemStyles = { fontWeight: "700" };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          backgroundColor: "#fff",
          color: "#000",
        }}
        elevation={1}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, ...menuItemStyles }}
          >
            brandon jones
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Link
              href="https://github.com/brandlej"
              target="_blank"
              sx={linkStyles}
            >
              <MenuItem sx={menuItemStyles}>github</MenuItem>
            </Link>
            <Link
              href="https://www.linkedin.com/in/brandonseanjones/"
              target="_blank"
              sx={linkStyles}
            >
              <MenuItem sx={menuItemStyles}>linkedin</MenuItem>
            </Link>
            <Link sx={linkStyles} href="mailto:jonesbrandonsean@gmail.com">
              <MenuItem sx={menuItemStyles}>contact me</MenuItem>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
};
