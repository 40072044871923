import { NavBar } from "./components/NavBar";
import { Home } from "./pages/Home";

export const App = () => {
  return (
    <>
      <NavBar />
      <Home />
    </>
  );
};
