import Container from "@mui/material/Container";
import { projects } from "../data/projects";
import { FeaturedWork } from "../components/FeaturedWork";
import { Introduction } from "../components/Introduction";

export const Home = () => {
  return (
    <Container>
      <Introduction />
      <FeaturedWork projects={projects} />
    </Container>
  );
};
